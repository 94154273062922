/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {/*       <script
              data-ad-client="ca-pub-7343489752686232"
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
            ></script> */}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `zh-TW`,
  meta: [],
  description: `充實還是忙碌? 希望在擁擠緊湊的城市中，疲累的靈魂得以喘息並來點異國語言的浪漫，如果還能有一份pizza就更好了；
  育兒之路，希望所有新手媽媽不孤單，全職媽媽更不寂寞，一起完成陪伴孩子成長又不迷失自我的目標；
  在“我願意”之後，跨國婚姻其實跟一般婚姻沒有不同，我們的溝通也許能給你靈感，或是你也給我們一點靈感；
  看看義式浪漫背後，最簡單卻用心的日常。`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
