import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Logo from "./logo"

import { rhythm } from "../utils/typography"
import logoSrc from "./../../content/assets/logo--orange.svg"
import "./../styles/main.css"
import { FooterComponent, FOOTER_HEIGHT } from "./footer"

const Wrapper = styled.div`
  background: var(--CREAM);
  min-height: 100vh;
  position: relative;
  z-index: 2;
  margin-bottom: ${FOOTER_HEIGHT};
`

const Header = styled.header`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 28vh;
  margin-bottom: 1rem;
`

const Slogan = styled.p`
  color: var(--CREAM);
  font-size: 1.4rem;
  padding: 0.4rem 1.5rem;
  display: inline-block;
  background: var(--CAPPUCCINO);

  &:first-child {
    margin-bottom: 0.8rem;
    border-top-left-radius: 70% 38%;
    border-top-right-radius: 36% 64%;
    border-bottom-left-radius: 24% 94%;
    border-bottom-right-radius: 72% 35%;
  }

  &:last-child {
    border-top-left-radius: 10% 88%;
    border-top-right-radius: 16% 44%;
    border-bottom-left-radius: 84% 14%;
    border-bottom-right-radius: 92% 25%;
  }
`

const Layout = props => {
  const {
    location,
    /* title, */
    children,
  } = props
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = <HomepageHeader />
  } else {
    header = <NonHomepageHeader />
  }
  return (
    <>
      <Wrapper>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Header> {header} </Header>
          <main
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // marginBottom: rhythm(2),
            }}
          >
            {children}
          </main>
        </div>
      </Wrapper>
      <FooterComponent />
    </>
  )
}

function NonHomepageHeader() {
  return (
    <Link
      style={{
        boxShadow: `none`,
        textDecoration: `none`,
        color: `inherit`,
        textAlign: "center",
      }}
      to={`/`}
    >
      <Logo src={logoSrc} />
    </Link>
  )
}

function HomepageHeader() {
  return (
    <>
      <Logo
        src={logoSrc}
        style={{
          marginBottom: "2.5rem",
        }}
      />
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Slogan> 你所不知道的義式角落 </Slogan> <br />
        <Slogan> 跨出那一步的生活 </Slogan>
      </div>
    </>
  )
}
export default Layout
