import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export const FOOTER_HEIGHT = "7rem"

const Footer = styled.footer`
  margin-top: auto;
  text-align: center;
  background: var(--CAPPUCCINO_TINT);
  padding: 1.2rem;
  display: grid;
  grid-row-gap: 0.4rem;
  justify-content: center;
  align-items: center;
  height: ${FOOTER_HEIGHT};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0;
`

export const FooterComponent = () => {
  return (
    <Footer>
      <nav>
        <Link
          style={{
            marginRight: "1rem",
          }}
          className="highlight--blue"
          to="/"
        >
          HOME
        </Link>
        <Link
          style={{
            marginRight: "1rem",
          }}
          className="highlight--blue"
          to="/關於我們"
        >
          關於我們
        </Link>
        <Link className="highlight--blue" to="/隱私權條款">
          隱私權條款
        </Link>
      </nav>
      <small>
        <span> Copyright© 2019 - {new Date().getFullYear()}, Italically. </span>
        <span> All Rights Reserved. </span>
      </small>
    </Footer>
  )
}
